var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-0  justify-content-center"},[_c('b-col',{staticClass:"content-header",attrs:{"cols":"9"}},[_c('h2',{staticClass:"content-header-title float-left m-0 pr-1 mr-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.pageTitle)+" ")]),_c('div',{staticClass:"content-header-search mr-1"},[_c('h3',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.companyForm.isParticular ? (_vm.companyForm.civility==0 ? 'M. ' : _vm.companyForm.civility==1 ? 'Mme ' : '')+ _vm.companyForm.firstName+ ' ' + _vm.companyForm.lastName : _vm.companyForm.name))])]),_c('div',{staticClass:"content-header-actions d-flex"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"24"},on:{"click":function($event){return _vm.cancel()}}})],1)])],1),(_vm.isLoadingCompany)?_c('div',{staticClass:"content-loader-center m-0 h-100"},[_vm._m(0)]):_c('b-row',{staticClass:"content-scrollable-sticky m-0 h-100 justify-content-center"},[_c('b-col',{staticClass:"content-scrollable-sticky-main",attrs:{"cols":"9"}},[_c('validation-observer',{ref:"formCreateCompany",attrs:{"autocomplete":"off"}},[_c('b-form',{attrs:{"autocomplete":"off"}},[_c('b-row',{staticClass:"my-0",attrs:{"id":"general"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"10"}},[_c('b-form-group',{attrs:{"label-for":"CustomerType"}},[_c('validation-provider',{attrs:{"name":"CustomerType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{model:{value:(_vm.companyForm.isParticular),callback:function ($$v) {_vm.$set(_vm.companyForm, "isParticular", $$v)},expression:"companyForm.isParticular"}},[_vm._v("Particulier ?")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end align-items-center",attrs:{"cols":"2"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Color"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-swatches',{attrs:{"state":errors.length > 0 ? false : null,"show-fallback":"","popover-x":"left","id":"color","show-border":false,"swatch-size":32,"trigger-style":{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' },"swatch-style":{ width: '32px', height: '32px', borderRadius: '50%',padding: '0px' },"wrapper-style":{ paddingLeft: '8px', paddingRight: '0px' }},model:{value:(_vm.companyForm.color),callback:function ($$v) {_vm.$set(_vm.companyForm, "color", $$v)},expression:"companyForm.color"}})]}}])})],1)],1)],1)],1),(!_vm.companyForm.isParticular)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nom de la société *","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":!_vm.companyForm.isParticular ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-typeahead-bootstrap',{attrs:{"state":"errors.length > 0 ? false : null","id":"name","disableSort":false,"ieCloseFix":false,"data":_vm.autocompleteCompanyNameData,"showAllResults":true,"serializer":function (item) { return item.nom_raison_sociale ? item.nom_raison_sociale: item.nom_complet; },"placeholder":"Rechercher une entreprise par Nom pour par Siret","aria-autocomplete":"both","aria-haspopup":"false","autocapitalize":"off","autocomplete":"off","autocorrect":"off","autofocus":"","role":"combobox","correcteur":"","orthographique":"false"},on:{"input":_vm.autocompleteCompanyNameSource,"hit":_vm.autocompleteCompanyNameSelected},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
var data = ref.data;
var htmlText = ref.htmlText;
return [_c('div',{staticClass:"item"},[_c('span',[_vm._v(_vm._s((data.nom_raison_sociale ? data.nom_raison_sociale: data.nom_complet)))]),_c('small',[_vm._v(_vm._s((data.siege.numero_voie? data.siege.numero_voie +' ' : '')+(data.siege.type_voie? data.siege.type_voie +' ' : '')+(data.siege.libelle_voie? data.siege.libelle_voie +' ' : '')+', '+data.siege.code_postal+ ' '+data.siege.libelle_commune))])])]}}],null,true),model:{value:(_vm.companyForm.name),callback:function ($$v) {_vm.$set(_vm.companyForm, "name", $$v)},expression:"companyForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3847884289)})],1)],1):_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Civilité","label-for":"civility"}},[_c('validation-provider',{attrs:{"name":"Civility"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"civility","state":errors.length > 0 ? false : null,"reduce":function (type) { return type.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.optionCivility},model:{value:(_vm.companyForm.civility),callback:function ($$v) {_vm.$set(_vm.companyForm, "civility", $$v)},expression:"companyForm.civility"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Prénom ","label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":"Firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","state":errors.length > 0 ? false : null},on:{"input":function($event){_vm.companyForm.name = _vm.companyForm.firstName +' '+_vm.companyForm.lastName}},model:{value:(_vm.companyForm.firstName),callback:function ($$v) {_vm.$set(_vm.companyForm, "firstName", $$v)},expression:"companyForm.firstName"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Nom *","label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"Lastname","rules":_vm.companyForm.isParticular ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false : null},on:{"input":function($event){_vm.companyForm.name = _vm.companyForm.firstName +' '+_vm.companyForm.lastName}},model:{value:(_vm.companyForm.lastName),callback:function ($$v) {_vm.$set(_vm.companyForm, "lastName", $$v)},expression:"companyForm.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_vm._l((_vm.companyForm.addresses),function(itemAddress,indexAddress){return _c('b-col',{key:indexAddress,attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":'labelAddress_' + indexAddress}},[_c('template',{slot:"label"},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('label',{class:_vm.companyForm.addresses.length > 1 ? 'font-weight-bolder':'',attrs:{"for":'labelAddress_' + indexAddress}},[_vm._v(_vm._s(_vm.companyForm.addresses.length > 1 ? 'Adresse ' + (indexAddress + 1) : 'Adresse'))]),(_vm.companyForm.addresses.length > 1)?_c('div',{staticClass:"d-flex"},[(itemAddress.default)?_c('check-circle-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Adresse par défaut'),expression:"'Adresse par défaut'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-class",attrs:{"color":itemAddress.default ? 'green' : '',"size":"1.5x"}}):_c('star-icon',{staticClass:"cursor-pointer custom-class",attrs:{"size":"1.5x"},on:{"click":function($event){return _vm.addFavoriteAddress(indexAddress)}}}),_c('trash-2-icon',{staticClass:"cursor-pointer custom-class",attrs:{"size":"1.5x"},on:{"click":function($event){return _vm.deleteAddress(itemAddress, indexAddress)}}})],1):_vm._e()])]),_c('validation-provider',{attrs:{"name":"LabelAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.companyForm.addresses.length > 1)?_c('b-form-input',{staticClass:"mb-1",attrs:{"id":'labelAddress_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Libellé (optionnel)"},model:{value:(itemAddress.label),callback:function ($$v) {_vm.$set(itemAddress, "label", $$v)},expression:"itemAddress.label"}}):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-typeahead-bootstrap',{staticClass:"mb-1 not-autocomplete",attrs:{"type":"text","state":errors.length > 0 ? false : null,"id":'address_' + indexAddress,"ieCloseFix":false,"data":_vm.autocompleteAddressData,"serializer":function (item) { return item.properties.label; },"showAllResults":true,"placeholder":"Rechercher une adresse ou saisissez : Rue et n° de rue","autocomplete":"nope","aria-autocomplete":"nope"},on:{"input":function($event){return _vm.autocompleteAddressSource(indexAddress)},"hit":_vm.autocompleteAddressSelected},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
var data = ref.data;
var htmlText = ref.htmlText;
return [_c('div',{staticClass:"item"},[_c('small',[_vm._v(_vm._s(data.properties.name))]),_c('span',[_vm._v(_vm._s(data.properties.postcode+ ' ' + data.properties.city))])])]}}],null,true),model:{value:(itemAddress.address),callback:function ($$v) {_vm.$set(itemAddress, "address", $$v)},expression:"itemAddress.address"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"AddressComplement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":'addressComplement_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Bâtiment, Appartement.."},model:{value:(itemAddress.addressComplement),callback:function ($$v) {_vm.$set(itemAddress, "addressComplement", $$v)},expression:"itemAddress.addressComplement"}})]}}],null,true)}),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Zipcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":'zipcode_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Code postal"},model:{value:(itemAddress.zipCode),callback:function ($$v) {_vm.$set(itemAddress, "zipCode", $$v)},expression:"itemAddress.zipCode"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":'city_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Ville"},model:{value:(itemAddress.city),callback:function ($$v) {_vm.$set(itemAddress, "city", $$v)},expression:"itemAddress.city"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('validation-provider',{attrs:{"name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-0",attrs:{"id":'country_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Pays"},model:{value:(itemAddress.country),callback:function ($$v) {_vm.$set(itemAddress, "country", $$v)},expression:"itemAddress.country"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],2)],1)}),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('small',{staticClass:"cursor-pointer text-primary",on:{"click":_vm.addAddress}},[_vm._v("+ Ajouter une adresse")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.companyForm.email),callback:function ($$v) {_vm.$set(_vm.companyForm, "email", $$v)},expression:"companyForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Téléphones","label-for":"phoneNumber"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"PhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('## ## ## ## ##'),expression:"'## ## ## ## ##'"}],attrs:{"id":"phoneNumber","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Principal"},model:{value:(_vm.companyForm.phoneNumber),callback:function ($$v) {_vm.$set(_vm.companyForm, "phoneNumber", $$v)},expression:"companyForm.phoneNumber"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"MobileNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('## ## ## ## ##'),expression:"'## ## ## ## ##'"}],attrs:{"id":"secondaryPhoneNumber","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Secondaire"},model:{value:(_vm.companyForm.secondaryPhoneNumber),callback:function ($$v) {_vm.$set(_vm.companyForm, "secondaryPhoneNumber", $$v)},expression:"companyForm.secondaryPhoneNumber"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Contacts associés","label-for":"contactIds"}},[_c('validation-provider',{attrs:{"name":"ContactIds"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"contactIds","multiple":"multiple","loading":_vm.isLoadingContact,"state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.contactsList.map(function (elem) { return { label: elem.firstName+' '+elem.lastName, value: elem.id }              }),"reduce":function (elem) { return elem.value; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [[_vm._v("Aucun résultat trouvé")]]},proxy:true}],null,true),model:{value:(_vm.companyForm.contactIds),callback:function ($$v) {_vm.$set(_vm.companyForm, "contactIds", $$v)},expression:"companyForm.contactIds"}},[_c('li',{staticClass:"border-top p-1",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"w-100",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.newContact()}}},[_vm._v("+ Nouveau contact")])],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('small',{staticClass:"cursor-pointer text-primary",on:{"click":function($event){_vm.displayMore = !_vm.displayMore}}},[_vm._v(_vm._s(_vm.displayMore ? '- Masquer les informations juridiques' : '+ Afficher les informations juridiques')+" ")])])],2),(_vm.displayMore)?_c('b-row',{staticClass:"my-0",attrs:{"id":"juridique"}},[(!_vm.companyForm.isParticular)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Siret","label-for":"siret"}},[_c('validation-provider',{attrs:{"name":"Siret"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"siret","state":errors.length > 0 ? false : null},model:{value:(_vm.companyForm.siret),callback:function ($$v) {_vm.$set(_vm.companyForm, "siret", $$v)},expression:"companyForm.siret"}})]}}],null,false,4017655859)})],1)],1):_vm._e(),(!_vm.companyForm.isParticular)?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Numéro de TVA","label-for":"tvaNumber"}},[_c('validation-provider',{attrs:{"name":"TvaNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tvaNumber","state":errors.length > 0 ? false : null},model:{value:(_vm.companyForm.tvaNumber),callback:function ($$v) {_vm.$set(_vm.companyForm, "tvaNumber", $$v)},expression:"companyForm.tvaNumber"}})]}}],null,false,1695017555)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Compte client","label-for":"companyAccount"}},[_c('validation-provider',{attrs:{"name":"CustomerAccount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"companyAccount","state":errors.length > 0 ? false : null},model:{value:(_vm.companyForm.companyAccount),callback:function ($$v) {_vm.$set(_vm.companyForm, "companyAccount", $$v)},expression:"companyForm.companyAccount"}})]}}],null,false,3630667635)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Notes","label-for":"notes"}},[_c('validation-provider',{attrs:{"name":"Notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"notes","state":errors.length > 0 ? false : null,"rows":"3"},model:{value:(_vm.companyForm.notes),callback:function ($$v) {_vm.$set(_vm.companyForm, "notes", $$v)},expression:"companyForm.notes"}})]}}],null,false,3124285491)})],1)],1)],1):_vm._e()],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mt-2",staticStyle:{"border-top":"1px solid #e2e2e2","padding-top":"0.5rem"}},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.cancel()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" Annuler ")],1),(_vm.companyForm.id)?_c('b-button',{staticClass:"btn-icon ml-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.archiveCompanyLocal(_vm.companyForm.id, _vm.companyForm.name)}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon"}})],1):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.create}},[(_vm.companyForm.id)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.companyForm.id ? 'Modifier' : 'Ajouter')+" ")],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center flex-center"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Chargement...")])]),_c('br'),_vm._v(" Chargement du formulaire... ")])}]

export { render, staticRenderFns }